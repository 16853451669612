import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Skeleton,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AgentForm from '@/components/forms/Agent.form';
import { Agent } from '@/interfaces/agent.interface';
import APIService from '@/services/API';
import { HomeOutlined, UserSwitchOutlined } from '@ant-design/icons';
export interface AgentDetailViewProps {
  edit?: boolean;
}
const AgentDetailView: React.FC<AgentDetailViewProps> = ({ edit = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const nav = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: agent, isLoading } = useQuery(
    ['agent', id],
    () => APIService.getAgent(id || ''),
    {
      onError: () => {
        nav('/dashboard/agents');
      },
      onSuccess(data) {
        form.setFieldsValue({
          ...data,
          birthday: dayjs(data?.birthday),
        });
      },
      retry: false,
    },
  );
  const onSubmit = async (values: Agent) => {
    setLoading(true);
    const response = id
      ? await APIService.updateAgent(id, values)
      : await APIService.createAgent(values);
    if (response.ok) {
      notification.success({
        message: id ? t('general.updateSuccess') : t('general.createSuccess'),
      });
      nav('/dashboard/agents');
    } else {
      notification.error({
        message: response.data?.message || t('general.saveError'),
      });
    }
    setLoading(false);
  };

  if (isLoading) {
    return <Skeleton active />;
  }
  return (
    <Card>
      <Row justify="space-between" align="middle">
        <Col>
          <Breadcrumb
            items={[
              {
                href: '#',
                onClick: () => nav('/dashboard'),
                title: <HomeOutlined />,
              },
              {
                href: '#',
                onClick: () => nav('/dashboard/agents'),
                title: (
                  <>
                    <UserSwitchOutlined />
                    <span>{t('menu.agents')}</span>
                  </>
                ),
              },
              {
                href: '#',
                title: (
                  <>
                    <span>{agent?.user?.full_name}</span>
                  </>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" gutter={[10, 10]}>
        <Col></Col>
        {edit && (
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                form.submit();
              }}
            >
              {edit && id ? t('general.edit') : t('general.create')}
            </Button>
          </Col>
        )}
        <Col span={24}>
          <AgentForm
            formRef={form}
            onSubmit={onSubmit}
            onlyView={!edit}
            profile={agent?.user?.profile}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AgentDetailView;
